import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="neuraltherapie" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Neuraltherapie`}</h1>
      <h3 {...{
        "id": "neuraltherapie"
      }}>{`Neuraltherapie`}</h3>
      <p>{`Bei der Neuraltherapie handelt es sich um die gezielte Behandlung des vegetativen Nervensystems mit einem Lokalanästetikum (Procain, Lidocain).`}</p>
      <p>{`Durch Injektion in Störfelder (alte Narben), schmerzhafte Muskelverhärtungen (Myogelosen) oder Akupunkturpunkte (s.a. `}<a parentName="p" {...{
          "href": "/therapien/injekto-akupunktur"
        }}>{`Injekto-Akupunktur`}</a>{`) wird über das Vegetativum eine regulierende Wirkung für den Gesamtorganismus erzielt. Blockierungen gestörter Regelkreise werden ausgeschaltet, so dass körpereigene Selbstheilungskräfte wieder in Gang kommen.`}</p>
      <p>{`Indikation:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/rueckenbeschwerden"
          }}>{`Rückenbeschwerden`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/arthrose_rheuma"
          }}>{`Arthrose / Rheuma`}</a></li>
        <li parentName="ul">{`Gelenkbeschwerden`}</li>
        <li parentName="ul">{`Kopfschmerzen`}</li>
        <li parentName="ul">{`Neuralgien`}</li>
        <li parentName="ul">{`störfeldbedingte chron. Krankheiten`}</li>
        <li parentName="ul">{`akute und chronische Schmerzen`}</li>
        <li parentName="ul">{`Reizmagen, Reizdarm`}</li>
      </ul>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      